.about-intro {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__container {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    font-family: "RedWingBold";

    h2 {
      margin-left: 5%;
      letter-spacing: 0.1em;
      font-size: 12vw;
      font-weight: 900;
      z-index: 1;
      color: #212020;

      span {
        -webkit-text-stroke: 1px #212020;
        color: #000000;
      }
    }

    h1 {
      font-size: 15vw;
      color: #ffffffe8;
      margin-left: 15%;
      margin-top: -0.5em;
      letter-spacing: 0.05em;

      // span {
      //   color: #000000;
      //   -webkit-text-stroke: 0.01px #ffffff;
      // }
    }
  }

  &__description {
    position: relative;
    padding-top: 3%;
    margin-left: 15%;
    color: #ffffff;
    font-weight: lighter;
    max-width: 50vw;
    padding-right: 8%;
    line-height: 1.5;
    p {
      margin-bottom: 1em;
    }
  }

  &__emoji {
    display: inline-block;
    position: absolute;
    bottom: -1.2em;
    right: 1em;
    font-size: 13em;
    transform: rotate(42deg);
    color: #58565638;
  }

  @media screen and (max-width: 998px) {
    &__container {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__header {
      text-align: left;
      width: 100%;

      h2 {
        margin-left: 0;
        font-size: 7em;
      }

      h1 {
        margin-left: 5%;
        font-size: 8em;
      }
    }

    &__description {
      margin-top: 2em;
      margin-left: 0;
      padding-right: 0;
      max-width: 100%;
    }

    &__emoji {
      margin-top: 0.5em;
    }
  }
}
