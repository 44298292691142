.contact {
  position: relative;
  top: 0;
  width: 100%;
  margin-top: 3em;
  padding-bottom: 4em;

  &__container {
    position: relative;
    top: 0;
    width: 100%;
    padding: 2% 5%;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__header {
    position: relative;
    top: 0;
    left: 5%;
    display: inline-block;
    text-align: left;
    font-family: 'RedWingBold';
    font-weight: 700;
    color: #212020;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 1.8em;
    margin-bottom: 3em;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 40%;
      height: 0.05em;
      background-color: #00ffff;
    }

    h1 {
      position: relative;
      color: #ffffff;
      span {
        position: relative;
      }
    }
  }

  &__details {
    width: 40%;
  }

  &__form {
    width: 100%;
    // border: 2px solid red;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;

    label {
      font-size: 1.1em;
      font-family: 'RedWingBold';
      letter-spacing: 0.05em;
      margin-bottom: 0.8em;
    }

    input {
      outline: none;
      background: transparent;
      border: none;
      font-family: 'Lato', sans-serif;
      color: #ffffff;
      padding-bottom: 0.3em;
      border-bottom: 0.03em solid #00ffff;
    }
    textarea {
      outline: none;
      background: transparent;
      border: none;
      color: #ffffff;
      font-family: 'Lato', sans-serif;
      border-bottom: 0.03em solid #00ffff;
      line-height: 1.5;
    }
  }

  &__send-btn {
    position: relative;
    right: 0;
    background: #00ffff;
    border: none;
    font-size: 1.1em;
    color: #000000;
    padding: 0.3em 1.5em;
    font-family: 'RedWingBold';
    cursor: pointer;
    float: right;
    margin-top: 1em;

    &:hover {
      color: #00ffff;
      background: transparent;
      border: 0.07em solid #00ffff;
    }
  }

  // information content styling
  &__info {
    color: #ffffff;
    width: 55%;
    padding-left: 10%;
  }

  &__info-title {
    position: relative;
    font-size: 1.5em;
    color: #00ffff;
    word-spacing: 0.5em;
    font-family: 'Sacramento', cursive;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 12%;
      height: 0.05em;
      background-color: #00ffff;
    }
  }

  &__info-details {
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 300;
    margin-top: 2em;
    ul {
      li {
        font-size: 1em;
        margin-bottom: 1.5em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          span {
            font-weight: 400;
          }
        }
        svg {
          color: #00ffff;
          font-size: 1.6em;
          margin-right: 0.5em;

          &:hover {
            color: #ff6347;
          }
        }
      }
    }
  }

  &__social {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 1em;

      a {
        font-size: 0.875em;
        svg {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }

  &__linkedin {
    a {
      svg:hover {
        color: #0072b1;
      }
    }
  }

  &__github {
    a {
      svg:hover {
        color: #00ffff;
      }
    }
  }

  &__youtube {
    a {
      svg:hover {
        color: #ff0000;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__header {
      font-size: 1.33em;
      margin-bottom: 1.5em;
    }

    &__container {
      flex-direction: column;
    }

    &__details {
      width: 90%;
    }

    &__info {
      padding-left: 2%;
      margin-top: 3em;
      width: 100%;
    }
  }
}
